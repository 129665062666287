import axios from 'axios';
import loadAndAppendMetadataToSeries from './loadAndAppendMetadataToSeries';
import { getSeriesModality, getSeriesUID } from '../utils/utils';
import { MGViewCodes } from '../../HangingProtocolView/features/ViewCodes/ViewCodes';
import seriesMatchViewCodeCondition from '../utils/seriesMatchViewCodeCondition';
import { sortSeries } from '../utils/sortSeriesThumbnails';
import { logDebug } from '@rs-core/index';

const SERIES_LASTUPDATEDTIMESTAMP = 'ramsoft-lastupdatedtimestamp';

const appedAdditionalDataToSeries = (series, managingOrganizationId) =>
	series.map((item, index) => {
		let mammographyViewCode;

		if (getSeriesModality(item).includes('MG')) {
			mammographyViewCode = MGViewCodes.filter(MGViewCode => !MGViewCode.includes('Any')).find(MGViewCode =>
				seriesMatchViewCodeCondition({
					viewCode: MGViewCode,
					series: item,
				})
			);
		}

		return {
			...item,
			uniqueId: `${getSeriesUID(item)}${index}`,
			mammographyViewCode,
			managingOrganizationId,
		};
	});

const loadSeriesWithMetadata = async ({
	__config,
	managingOrganizationId,
	studyInstanceUID,
	wonIvSeriesSplitter,
	is3D,
	wonIvPlaneSplitter,
	wonIvDwiseriessplitter,
	wonIvDisablexasplit,
	cacheLinks,
	wonIvInitImageMetadata,
	wonIvEnhancedcurvedsort,
	wonIvRefactorsorting,
	wonIvStillsfirst,
	isBlumeApp,
	creatorUserId,
	isUploaded,
	isEmergencySearch,
	internalStudyId,
}) => {
	const headers = {
		'ramsoft-internalmanagingorganizationid': managingOrganizationId,
		'ramsoft-internalstudyid': internalStudyId,
		IsEmergencySearch: isEmergencySearch, // if IV access from Emergency Search, add header
	};

	let url = `${__config.data_sources.dicom_web}/studies/${studyInstanceUID}/series?BlumePatientId=${
		creatorUserId || null
	}`;
	if (isBlumeApp) {
		url = `${__config.data_sources.blume}studies/${studyInstanceUID}/series?creatorBlumeUserId=${creatorUserId}&isUploaded=${isUploaded}`;
	}
	const response = await axios.get(url, {
		headers,
	});

	if (response?.data) {
		let seriesLastUpdatedTimestamp = [];
		if (response.headers) {
			if (response.headers[SERIES_LASTUPDATEDTIMESTAMP]) {
				seriesLastUpdatedTimestamp = response.headers[SERIES_LASTUPDATEDTIMESTAMP].split(',');
			}
		}

		const mapLastUpdatedTimestamp = index => {
			if (seriesLastUpdatedTimestamp.length >= index + 1) {
				return seriesLastUpdatedTimestamp[index];
			}
			return null;
		};

		const series = response.data.map((item, index) => ({
			...item,
			lastUpdatedTimestamp: mapLastUpdatedTimestamp(index),
		}));

		logDebug('IV::LoadMetadata::StudyLevel', 'Study series - Start', { timestamp: Date.now(), studyInstanceUID });
		const _series = await loadAndAppendMetadataToSeries({
			__config,
			headers,
			studyInstanceUID,
			series,
			wonIvSeriesSplitter,
			is3D,
			wonIvPlaneSplitter,
			wonIvDwiseriessplitter,
			wonIvDisablexasplit,
			cacheLinks,
			useCornerstone: true,
			managingOrganizationId,
			wonIvInitImageMetadata,
			wonIvEnhancedcurvedsort,
			wonIvRefactorsorting,
			isBlumeApp,
			creatorUserId,
			isUploaded,
		});

		logDebug('IV::LoadMetadata::StudyLevel', 'Sort Series - Start', { timestamp: Date.now(), studyInstanceUID });
		const seriesWithMetadata = _series.filter(item => item.metadata.length);
		const sortedSeries = sortSeries(appedAdditionalDataToSeries(seriesWithMetadata, managingOrganizationId));

		if (wonIvStillsfirst) {
			const [common, cine] = sortedSeries.reduce(
				([p, f], i) =>
					i.metadata?.[0]?.['00280008']?.Value?.[0] && i.metadata?.[0]?.['00080060']?.Value?.[0] === 'US'
						? [p, [...f, i]]
						: [[...p, i], f],
				[[], []]
			);

			return [...common, ...cine];
		}
		logDebug('IV::LoadMetadata::StudyLevel', 'Sort Series - End', { timestamp: Date.now(), studyInstanceUID });
		logDebug('IV::LoadMetadata::StudyLevel', 'Study series - End', { timestamp: Date.now(), studyInstanceUID });
		return sortedSeries;
	}

	return [];
};

export default loadSeriesWithMetadata;
