import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import use100vh from '../../Mobile100vh/use100vh';
import Backdrop from '@mui/material/Backdrop';

import { mediaTablet, useIsTablet } from '@worklist-2/core/src/utils/responsiveUtils';
import { useAppModeContext } from '@worklist-2/core/src/context/AppModeContext';
import { useAuth } from '@worklist-2/core/src/context/UserAuthContext';

import SideBarHeader from '../SideBarHeader';
import SideBarNav from '../SideBarNav';
import SideBarAdmin from '../SideBarAdmin';
import logo from '@worklist-2/ui/src/assets/img/blume_new_logo.png';
import smallLogo from '@worklist-2/ui/src/assets/img/blume.png';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const openWidth = 280;
const closedWidth = 64;

const defaultHeaderButtons = [
	{
		path: '',
		text: '',
		icon: 'hamburger',
		iconType: 'svg',
	},
	{ path: '', icon: 'addnew', text: 'Add new', iconType: 'svg' },
];

export const openedMixin = (theme, appMode, blumeNewHomePage) => ({
	width: openWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	...(appMode === 'patientPortal' && {
		width: '205px !important',
		...(blumeNewHomePage
			? {
					backgroundColor: 'white',
			  }
			: {
					maxHeight: 'calc(100vh - 28px)',
					borderRadius: '25px',
					left: '15px',
					top: '14px',
					bottom: '14px',
			  }),
		[mediaTablet]: {
			left: '0',
			top: '0',
			bottom: '0',
			maxHeight: 'unset',
			borderRadius: '0',
			height: '100vh !important',
			width: '192px !important',
			position: 'fixed',
			zIndex: '1060',
			borderTopRightRadius: '25px',
			borderBottomRightRadius: '25px',
		},
	}),
});

export const closedMixin = (theme, appMode, blumeNewHomePage) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: closedWidth,
	...(appMode === 'patientPortal' && blumeNewHomePage
		? { backgroundColor: 'white' }
		: appMode === 'patientPortal' && {
				maxHeight: 'calc(100vh - 28px)',
				borderRadius: '25px',
				left: '15px',
				top: '14px',
				bottom: '14px',
		  }),
	[mediaTablet]: {
		left: '0',
		top: '0',
		bottom: '0',
		maxHeight: 'unset',
		borderRadius: '0',
		height: '58px',
		'& .nav-section': {
			display: 'none',
		},
	},
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: prop => prop !== 'open' && prop !== 'appMode',
})(({ theme, open, appMode, blumeNewHomePage }) => ({
	width: openWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(appMode === 'patientPortal' && blumeNewHomePage
		? {
				'& .MuiSvgIcon-root': {
					color: 'inherit !important',
				},
				'& .nav-link': {
					backgroundColor: 'inherit !important',
					fontSize: '16px !important',
					fontWeight: 500,
					color: 'black !important',
					cursor: 'pointer',
				},
				'& .nav-link-active': {
					color: '#42a5f5 !important',
					backgroundColor: '#42a5f50f !important',
				},
				'& .nav-link-active .MuiSvgIcon-root': {
					color: '#42a5f5',
				},
		  }
		: {}),
	...(open && {
		...openedMixin(theme, appMode, blumeNewHomePage),
		'& .MuiDrawer-paper': openedMixin(theme, appMode, blumeNewHomePage),
	}),
	...(!open && {
		...closedMixin(theme, appMode, blumeNewHomePage),
		'& .MuiDrawer-paper': closedMixin(theme, appMode, blumeNewHomePage),
	}),
}));

const SideBar = ({
	activeClassName,
	navButtons,
	adminButtons,
	headerButtons,
	className,
	open,
	setSideBarIsOpen,
	loggedInUser,
	setCanViewAddNewOrder,
	setCanViewAddNewImport,
	launchNewHelpManual,
	onHelpManualClick,
	onAddClick,
	onClickOpenClose,
	onClickLogo,
}) => {
	const { t } = useTranslation('sideMenu');
	const { appMode } = useAppModeContext();
	const proactHideMarketplace = useBooleanFlagValue('proact-hide-marketplace');
	const maven2061TeachingFolder = useBooleanFlagValue('maven-2061-teaching-folder');
	const { userInOmegaAI, globalPermissionsForLoggedUser } = useAuth();
	const { pathname } = useLocation();
	const hidden = pathname.startsWith('/externalImageViewer') || pathname.startsWith('/external-form');
	const [drawerHovered, setDrawerHovered] = useState(false);

	const patientPortalNavButtons = [
		{
			path: 'home',
			text: t('home'),
			icon: 'home',
			iconType: 'svg',
			tooltipTxt: open ? '' : t('home'),
		},
	];

	const defaultAdminButtons = [
		{
			path: 'marketplace',
			icon: 'myapp',
			text: t('marketplace'),
			iconType: 'svg',
			view: 'worklist|marketplace|helpCenter',
			tooltipTxt: open ? '' : t('marketplace'),
		},
		{
			path: 'apps',
			icon: 'apps',
			text: t('myApps'),
			iconType: 'svg',
			view: '',
			tooltipTxt: open ? '' : t('apps'),
		},
		{
			path: launchNewHelpManual ? '' : 'help',
			icon: 'help',
			text: t('help'),
			iconType: 'svg',
			view: 'worklist|marketplace|helpCenter',
			tooltipTxt: open ? '' : t('help'),
			onClick: onHelpManualClick,
		},
		{
			path: 'help',
			icon: 'help',
			text: t('help'),
			iconType: 'svg',
			view: 'patientPortal',
			tooltipTxt: open ? '' : t('help'),
		},
		{
			path: 'profile',
			icon: 'omega-ai-icon',
			text: 'RamSoft Inc.',
			iconType: 'png',
			view: '',
			tooltipTxt: open ? '' : t('My Profile'),
		},
	];

	const defaultWorklistNavButtons = [
		{
			path: 'home',
			icon: 'home',
			text: t('worklist'),
			iconType: 'svg',
			tooltipTxt: t('worklist'),
		},
		{
			path: 'organization',
			icon: 'organization',
			text: t('organization'),
			iconType: 'svg',
			tooltipTxt: t('organization'),
		},
		{
			path: 'log',
			icon: 'log',
			text: t('logs'),
			iconType: 'svg',
			tooltipTxt: t('logs'),
		},
	];

	if (maven2061TeachingFolder) {
		defaultWorklistNavButtons.push({
			path: 'teaching-folder',
			icon: 'teaching',
			text: t('Teaching Folder'),
			iconType: 'svg',
			tooltipTxt: t('Teaching Folder'),
		});
	}

	const customOpenWidth =
		appMode == 'worklist' || appMode == 'marketplace' || appMode == 'helpCenter' ? openWidth + 10 : 230;
	const customClosedWidth =
		appMode == 'worklist' || appMode == 'marketplace' || appMode == 'helpCenter'
			? closedWidth + 10
			: closedWidth + 25;
	headerButtons = headerButtons || defaultHeaderButtons;

	const hasMarketplacePermission = !proactHideMarketplace && globalPermissionsForLoggedUser?.Marketplace;

	const newAdminButtons = defaultAdminButtons.filter(item => {
		if (item.view == '' && appMode != 'patientPortal') {
			return item;
		}
		if (appMode === 'patientPortal' && item.view == '') {
			if (item.path === 'apps' && !userInOmegaAI) {
				return;
			}
			return item;
		}

		if (item.view.split('|').indexOf(appMode) > -1) {
			if (item.path == 'marketplace' && !hasMarketplacePermission) {
				return;
			}

			return item;
		}
	});

	let canViewAddNew = false;
	let canViewLogs = false;
	let canViewOrgs = false;

	if (appMode !== 'patientPortal') {
		// Will show Logs button if User has permission to view Task Log, Audit Log or Activity History

		canViewAddNew =
			globalPermissionsForLoggedUser?.AddNew &&
			(globalPermissionsForLoggedUser?.AddNewOrder || globalPermissionsForLoggedUser?.Import);
		canViewLogs = globalPermissionsForLoggedUser?.Logs;
		canViewOrgs = globalPermissionsForLoggedUser?.Organizations;

		if (globalPermissionsForLoggedUser?.AddNewOrder) {
			setCanViewAddNewOrder(true);
		}

		if (globalPermissionsForLoggedUser?.Import) {
			setCanViewAddNewImport(true);
		}
	}

	const worklistNavButtons = defaultWorklistNavButtons.filter(item => {
		if (item.path === 'log' && !canViewLogs) {
			return;
		}

		if (item.path === 'organization' && !canViewOrgs) {
			return;
		}

		return item;
	});

	adminButtons = adminButtons || newAdminButtons;
	navButtons =
		navButtons || appMode == 'worklist' || appMode == 'marketplace' || appMode == 'helpCenter'
			? worklistNavButtons
			: patientPortalNavButtons;
	className = className || 'sidebar';
	activeClassName = activeClassName || 'nav-link-active';

	const addProfileBtn = () => {
		if (!loggedInUser) {
			return adminButtons;
		}
		let newAdminBtns;
		const profileIdx = adminButtons.findIndex(elem => elem.path === 'profile');
		if (profileIdx !== -1) {
			newAdminBtns = adminButtons.slice(0, profileIdx).concat(adminButtons.slice(profileIdx + 1));
		}
		newAdminBtns.push(loggedInUser);
		return newAdminBtns;
	};

	const isTablet = useIsTablet();
	const height = use100vh();

	return (
		<>
			{appMode === 'patientPortal' && (
				<IconButton
					data-testid="blume-close"
					sx={{
						height: '30px',
						width: '30px',
						background: '#dadada',
						position: 'fixed',
						zIndex: 9999,
						left: open ? '190px' : '50px',
						top: '26px',
						backgroundColor: '#EBF5FE',
						color: 'var(--color-primary)',
						visibility: drawerHovered ? 'visible' : 'hidden',
					}}
					onClick={onClickOpenClose}
					onMouseEnter={() => setDrawerHovered(true)}
					onMouseLeave={() => setDrawerHovered(false)}
				>
					<Icon component={open ? KeyboardDoubleArrowLeft : KeyboardDoubleArrowRight} fontSize="small" />
				</IconButton>
			)}
			<Drawer
				blumeNewHomePage
				appMode={appMode}
				className={classnames(`sidebar-${open ? 'open' : 'closed'}`, className)}
				data-cy="sidebar-drawer"
				elevation={0}
				open={open}
				sx={{
					height: 'unset !important',
					display: hidden ? 'none' : 'flex',
					[mediaTablet]: {
						height: open ? (height ? `${height}px !important` : '100vh !important') : 'unset !important',
					},
					'& .MuiDrawer-paper': {
						zIndex: '6 !important',
						[mediaTablet]: {
							zIndex: '1060 !important',
							height: open ? (height ? `${height}px !important` : '100vh !important') : '58px !important',
						},
					},
				}}
				variant="permanent"
				onMouseEnter={() => setDrawerHovered(true)}
				onMouseLeave={() => setDrawerHovered(false)}
			>
				{appMode === 'patientPortal' && !isTablet ? (
					<img
						alt="logo"
						data-cy="sidebar-hamburger"
						height={open ? 24 : 30}
						src={open ? logo : smallLogo}
						style={{
							margin: open ? '30px 20px' : '30px 5px',
							objectFit: 'contain',
							cusor: !open && 'pointer',
						}}
						width={open ? 93.87 : 60}
						onClick={onClickLogo}
					/>
				) : (
					<SideBarHeader
						buttons={headerButtons}
						handleDrawerClick={onClickOpenClose}
						hideAddNew={!canViewAddNew}
						onAddClick={onAddClick}
					/>
				)}
				{(appMode == 'worklist' || appMode == 'marketplace' || appMode == 'helpCenter') && <Divider />}
				<Box className="nav-section">
					<SideBarNav activeClassName={activeClassName} buttons={navButtons} />
					<SideBarAdmin
						activeClassName={activeClassName}
						buttons={addProfileBtn()}
						hideProfile={appMode === 'patientPortal'}
						sidebarWidth={open ? customOpenWidth : customClosedWidth}
						t={t}
					/>
				</Box>
			</Drawer>

			{isTablet && (
				<Backdrop
					open={open}
					sx={{
						zIndex: '1',
					}}
					onClick={() => setSideBarIsOpen?.(false)}
				/>
			)}
		</>
	);
};

const btnShape = PropTypes.shape({
	path: PropTypes.string.isRequired,
	icon: PropTypes.string,
	text: PropTypes.string,
});

SideBar.propTypes = {
	activeClassName: PropTypes.string,
	headerButtons: PropTypes.arrayOf(btnShape),
	adminButtons: PropTypes.arrayOf(btnShape),
	navButtons: PropTypes.arrayOf(btnShape),
	loggedInUser: btnShape,
	onClickOpenClose: PropTypes.func,
	onClickLogo: PropTypes.func,
	open: PropTypes.bool,
};

export default SideBar;
