import { LOGIN_SUCCESS } from './eventTypes';
import { logInfo } from '@worklist-2/core/src';
import mixpanel from 'mixpanel-browser';

const config = JSON.parse(process.env.NODE_CONFIG || '{}');

let defaultParams = {};

function sendAnalyticsEvent(eventName, eventParams) {
	console.log('config', config?.mixpanel);
	// if (!config?.mixpanel) {
	// 	logInfo('Mixpanel config not found');
	// 	return;
	// }
	// if (config.mixpanel.includes('MIXPANEL_TOKEN')) {
	// 	logInfo('Mixpanel token not found', config.mixpanel);
	// 	return;
	// }
	// if (!mixpanel?.identify || !mixpanel?.track) {
	// 	return;
	// }
	// if (eventName === LOGIN_SUCCESS) {
	// 	defaultParams = eventParams;
	// 	mixpanel.people.set({
	// 		$name: eventParams.name,
	// 		$email: eventParams.email,
	// 		USER_ID: eventParams.id,
	// 		isBlumeSynced: eventParams.isBlumeSynced,
	// 	});
	// }
	// const payload = { ...defaultParams, ...eventParams };
	// mixpanel.identify(payload.id);
	// mixpanel.track(eventName, payload);
	// logInfo('Analytics event sent:', eventName, payload);
}

export default sendAnalyticsEvent;
